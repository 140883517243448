<template>
  <div class="right-menu">
    <div class="nickname">{{ nickname }}</div>
    <el-dropdown class="avatar-container right-menu-item">
      <div class="avatar-wrapper">
        <img :src="require('@/assets/img/avatar.png')" class="user-avatar" />
        <I name="CaretBottom" />
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item @click="loginOut">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
// import { user_logout } from '@/api/user'
import { computed } from 'vue'
const router = useRouter()
// 退出
const loginOut = () => {
  localStorage.clear()
  // router.push('/login')

  window.location.href = `${location.href.split('#')[0]}/#/login`
  // window.location.reload()
  // setTimeout(() => {
  //   window.location.reload()
  // }, 10)
  // user_logout().then(({data:res}) => {
  //   if (res.code == 200) {
  //     localStorage.clear()
  //     router.push('/login')
  //   }
  // })
}

const nickname = computed(() => {
  return localStorage.getItem('role')
})
</script>
<style lang="scss" scoped>
.right-menu {
  display: flex;
  align-items: center;
  float: right;
  height: 100%;

  &:focus {
    outline: none;
  }
  .menu-icon {
    width: 42px;
    width: 42px;
    cursor: pointer;
    margin-right: 20px;
  }
  .right-menu-item {
    display: inline-block;
    margin: 0 4px;
    vertical-align: middle;
  }

  .international {
    vertical-align: top;
  }

  .theme-switch {
    vertical-align: 15px;
  }

  .avatar-container {
    // height: 50px;
    margin-right: 10px;

    .avatar-wrapper {
      position: relative;

      .user-avatar {
        width: 30px;
        height: 30px;
        cursor: pointer;
        border-radius: 10px;
      }

      .el-icon-caret-bottom {
        position: absolute;
        top: 20px;
        right: -16px;
        font-size: 12px;
      }
    }
  }
}
</style>
<style lang="scss">
.perDialogVisible {
  .el-input {
    width: 200px;
  }
  .el-textarea {
    width: 200px;
  }
}
</style>
