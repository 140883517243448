<template>
  <div class="sidebar-container">
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :router="true"
        class="v-enter-to"
        :default-active="$route.path"
        :collapse="isCollapse"
        :show-timeout="200"
        text-color="#fff"
        background-color="#4a5a74"
        active-text-color="#409EFF"
        unique-opened
      >
        <sidebar-item
          v-for="item in routerList"
          :key="item.path"
          :index="item.path"
          :nav="item"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import store from '@/store'
import SidebarItem from './SidebarItem.vue'
// const routerList = asyncRoutes[0].children
const opened = computed(() => store.state.app.sidebar.opened)
const routerList = computed(() => store.state.app.menu)
const isCollapse = computed(() => !opened.value)
</script>

<style lang="scss" scoped="scoped"></style>
