<template>
  <div class="navbar" mode="horizontal">
    <div class="logo">
      <span class="logo-text">朝日唯品</span>
    </div>
    <Hamburger
      class="hamburger-container"
      :is-active="opened"
      @toggleClick="toggleSideBar"
    />
    <Breadcrumb class="breadcrumb-container" />
    <UserView url="projectManagement" />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import store from '@/store'
import Hamburger from './Hamburger.vue'
import Breadcrumb from './Breadcrumb.vue'
import UserView from '@/components/UserView.vue'
// import Screenfull from './Screenfull.vue'

const opened = computed(() => store.state.app.sidebar.opened)

const toggleSideBar = () => {
  store.dispatch('app/toggleSideBar')
}
</script>

<style lang="scss" scoped>
.logo {
  width: $sideBarWidth;
  height: $navHeight;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  background-color: #263445;
  float: left;
  .logo-img {
    width: 32px;
    height: 32px;
  }

  .logo-text {
    display: inline-block;
    height: 50px;
    font-size: 18px;
    line-height: 50px;
    color: #fff;
  }
}
.navbar {
  width: 100%;
  height: $navHeight;
  overflow: hidden;
  line-height: $navHeight;

  .hamburger-container {
    float: left;
    height: 50px;
    padding: 0 10px;
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }
}
</style>
